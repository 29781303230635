var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"item-key":"id","loading":_vm.isLoading,"loading-text":"Loading... Please wait","headers":_vm.getHeader(),"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status_id),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatusValue(item.status_id))+" ")])]}},(_vm.userType === 'guardians')?{key:"item.role_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.role_id === 4? 'primary' : 'info'}},[_vm._v(" "+_vm._s(item.role_id === 4? 'Parent' : 'Guardian')+" ")])]}}:null,(_vm.userType === 'drivers')?{key:"item.bus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getBusAssignmentColor(item.bus)},on:{"click":function($event){return _vm.$emit('assignBus', item)}}},[_vm._v(" "+_vm._s(item.bus != null? item.bus.license : 'No bus')+" ")])]}}:null,{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LL")))]),_vm._v(" - "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LT")))])]}},{key:"item.student_settings.morning_bus",fn:function(ref){
var item = ref.item;
return [(_vm.tab!=2 && _vm.userType === 'students' && _vm.mode === 'simple')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getBusAssignmentColor(item.student_settings.morning_bus)},on:{"click":function($event){return _vm.$emit('set-student-bus', item, 1)}}},[_vm._v(" "+_vm._s(item.student_settings.morning_bus != null? item.student_settings.morning_bus.license : 'No bus')+" ")])]}}],null,true)},[_c('span',[_vm._v("Set morning bus")])]):_vm._e()]}},{key:"item.student_settings.afternoon_bus",fn:function(ref){
var item = ref.item;
return [(_vm.tab!=2 && _vm.userType === 'students' && _vm.mode === 'simple')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getBusAssignmentColor(item.student_settings.afternoon_bus)},on:{"click":function($event){return _vm.$emit('set-student-bus', item, 0)}}},[_vm._v(" "+_vm._s(item.student_settings.afternoon_bus != null? item.student_settings.afternoon_bus.license : 'No bus')+" ")])]}}],null,true)},[_c('span',[_vm._v("Set afternoon bus")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.tab==2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 primary",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('view-user', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-card-account-mail-outline ")]),_vm._v(" Take Action ")],1)]}}],null,true)},[_c('span',[_vm._v("Approve or Reject")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('view-user', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("View")])]),(_vm.tab!=2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('edit-user', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.tab!=2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.$emit('suspend-user', item, _vm.users.indexOf(item))}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.status_id!=1? _vm.icons.mdiAccountCheck: _vm.icons.mdiAccountOff)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status_id!=1? 'Activate' : 'Suspend'))])]):_vm._e(),(_vm.tab!=2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.userType === 'drivers' && item.bus)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('unassign-bus', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-fridge-industrial-off ")]):(_vm.userType === 'drivers')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('assign-bus', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-bus ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.bus? 'Un-assign bus' : 'Assign bus'))])]):_vm._e(),(_vm.tab!=2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.userType === 'schools')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('login-as-school', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-login ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Login as this school")])]):_vm._e(),(_vm.tab!=2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.userType === 'schools')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('delete-school', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete this school")])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }